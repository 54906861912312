import React from "react"
import { withPrefix } from "gatsby"
import { Helmet } from "react-helmet"
import "../assets/vendor/css/jquery.fancybox.min.css"

import Layout from "../components/layout"
import ConfianEnNosotros from "../components/confian-en-nosotros"

import FondoNosotros from "../assets/old/img/us/header.jpg"

export default () => (
  <Layout menuActivo="nosotros">
    <Helmet>
      <title>Sobre Nosotros</title>
      <meta
        name="description"
        content="Web del Seguro nace en el año 2010, de la mano de Smart Seguros. como uno de los primeros proyectos de seguros online de Argentina."
      />
      <script src={withPrefix("/js/jquery.fancybox.min.js")} />
    </Helmet>

    <section className="bg-secondary pb-5">
      <div className="col-12">
        <div className="row">
          <div
            className="col-lg-6"
            style={{
              minHeight: "300px",
              backgroundImage: `url(${FondoNosotros})`,
              backgroundPosition: "50% 50%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              marginBottom: 0,
            }}
          />

          <div className="col-lg-6 bg-sombra-centro">
            <div className="blog-card py-2 px-3">
              <div className="card-body">
                <h2 className="post-title" style={{ fontSize: 28 }}>
                  Webdelseguro.com.ar
                </h2>
                <p>
                  Nacemos en el año 2010, de la mano de Smart Seguros. como uno de los primeros
                  proyectos de seguros online de Argentina. Persiguiendo un claro objetivo desde
                  entonces, facilitar y agilizar la contratación y posterior gestión de sus pólizas
                  de seguro.
                </p>
                <p>
                  Uno de los pilares que caracterizan nuestra compañía desde entonces, es brindar
                  asesoramiento específico y especializado por expertos productores de seguros.
                </p>
                <p>
                  webdelseguro.com.ar, cuenta con un equipo de 30 profesionales involucrados día a
                  día en mejorar la calidad del servicio y la atención al cliente. Distribuidos
                  entre las siguientes áreas, atención al cliente por expertos en seguros,
                  marketing, desarrollo, programación, soporte técnico, administración, post venta,
                  siniestros.
                </p>
              </div>
            </div>

            <div
              className="bg-sombra-pie"
              style={{
                bottom: "-10px",
              }}
            />
          </div>
        </div>
      </div>

      {/* Gallery */}
      <div className="container-fluid pt-xl-3 mt-5">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="bg-white box-shadow px-3 px-sm-5 pt-4 pt-md-5 pb-4">
              <h2 className="h3 text-center text-sm-left">Nuestras instalaciones</h2>
              <div className="row pt-3">

                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                    className="gallery-item"
                    href={require("../assets/old/img/us/gallery/01.jpg")}
                    data-fancybox="gallery"
                    data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                      src={require("../assets/old/img/us/gallery/small/01.jpg")}
                      alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/02.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/02.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/03.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/03.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/04.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/04.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/05.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/05.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/06.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/06.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/07.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/07.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/08.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/08.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/09.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/09.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/10.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/10.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/11.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/11.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/12.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/12.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/13.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/13.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/14.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/14.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>
                <div className="col-md-4 col-sm-6 mb-30">
                  <a
                      className="gallery-item"
                      href={require("../assets/old/img/us/gallery/15.jpg")}
                      data-fancybox="gallery"
                      data-options='{"caption": "Oficina Web del Seguro"}'
                  >
                    <img
                        src={require("../assets/old/img/us/gallery/small/15.jpg")}
                        alt="Oficinas Web del Seguro"
                    />
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Aseguradoras */}
      <ConfianEnNosotros />
    </section>
  </Layout>
)
